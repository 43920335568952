@import '../../variables.scss';

.babypage-actions {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $primary-color;
  font-size: 13pt;

  .babypage-action {
    cursor: pointer;

    &:hover {
      color: $secondary-color;
    }
  }
}

.babypage-preview {
  margin-bottom: 80px;
  cursor: pointer;

  .babypage-image {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }

  .babypage-content {
    width: 160px;
    background-color: #fff;
    left: 53%;
    transform: translate(-50%, -50%);
    max-width: 160px;
    min-height: 91px;
    position: absolute;
    padding: .5em;
    border-radius: 8px;
    box-shadow: 0 6px 10px 0 rgba(230, 230, 230, 0.5);

    @media (max-width: 500px) {
      min-height: 68px !important;
    }

    @media (max-width: 320px) {
      transform: translate(-45%, -50%);
    }

    .babypage-text {
      width: 100%;
    }

    .title {
      text-align: center;
      font-size: 11px;
      margin: 0;
      font-family: "MontserratSemiBold";
      color: #111111;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 10px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Montserrat";
      color: #949494;

      @media (min-width: 768px) {
        font-size: 12px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
      }
    }

    @media (max-width: 500px) {
      width: 83%;
    }
  }
}

.confirmation-question {
  color: rgba(0, 0, 0, .65);
  font-weight: bold;
}
